<template>
  <div>
    <div>
      <span style="font-size: 12px;">数据域概述：</span>
      <div
        class="datafileblock"
        v-for="item in options"
        :key="item.title"
      >
        <span style="font-size: 12px; color:#9196a1 ;">{{item.title}}</span>
        <br />
        <span style="font-size: 24px;">{{item.num}}</span>

      </div>
      <el-form :inline="true">
        <el-form-item>
          <el-button
            type="primary"
            @click="addDatafile"
          >新增数据域</el-button>
          <el-button
            type="danger"
            :disabled="multipleSelection.length==0"
            @click="deleteMany"
            v-if="setting.batchRemove"
          >批量删除</el-button>
        </el-form-item>
        <el-form-item>
          <el-input
            v-model="dataFieldName"
            placeholder="请输入数据域名称"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click="queryBydataFieldName()"
          >查询</el-button>
        </el-form-item>
        <el-form-item>
          <el-button>重置</el-button>
        </el-form-item>
        <el-select
          v-if="setting.advancesearch"
          v-model="coalValue"
          style="margin-left: 10px;width: 150px"
          placeholder="请选择项目"
          @change="queryByCondition()"
        >
          <el-option
            v-for="item in coaloptions"
            :key="item.value"
            :label="item.label"
            :value="item.label"
          >
          </el-option>
        </el-select>
        <el-select
          v-if="setting.advancesearch"
          v-model="systemValue"
          style="margin-left: 10px;width: 150px"
          placeholder="请选择系统"
          @change="queryByCondition()"
        >
          <el-option
            v-for="item in systemoptions"
            :key="item.value"
            :label="item.label"
            :value="item.label"
          >
          </el-option>
        </el-select>
      </el-form>
      <el-card>
        <el-table
          :data="tableData"
          @selection-change="handleSelectionChange"
          style="width: 100%"
          border
          stripe
        >
          <el-table-column
            type="selection"
            header-align="center"
            align="center"
            width="50"
            v-if="setting.batchRemove"
          >
          </el-table-column>
          <el-table-column
            prop="dataField"
            label="数据域"
            header-align="center"
            align="center"
            width="180"
          >
          </el-table-column>
          <!-- <el-table-column prop="coal" label="所属项目" header-align="center" align="center" width="180">
					</el-table-column>
					<el-table-column prop="system" label="所属系统" header-align="center" align="center" width="220">
					</el-table-column> -->
          <el-table-column
            prop="createDate"
            label="创建时间"
            header-align="center"
            align="center"
            width="220"
          >
          </el-table-column>
          <el-table-column
            prop="remark"
            label="概述"
            header-align="center"
            align="center"
            width="220"
          >
          </el-table-column>
          <el-table-column
            label="操作"
            header-align="center"
            align="center"
            width="180"
          >
            <template slot-scope="scope">
              <el-button
                type="text"
                size="small"
                @click="watchdatafile(scope.row)"
              >查看</el-button>
              <el-button
                type="text"
                size="small"
                @click="updatedatafile(scope.row)"
              >修改</el-button>
              <el-button
                type="text"
                size="small"
                style="color: red;"
                @click="deleteDataField(scope.row.id)"
              >删除</el-button>
            </template>
          </el-table-column>

        </el-table>
        <div style="display: flex;justify-content: flex-end">
          <el-pagination
            background
            @current-change="currentChange"
            @size-change="sizeChange"
            :current-page="page"
            :page-size="size"
            layout="sizes, prev, pager, next, jumper, ->, total, slot"
            :total="total"
          >
          </el-pagination>
        </div>
      </el-card>
    </div>
    <div v-else-if>
      <router-view></router-view>
    </div>

    <!-- 新增数据域对话框-->
    <div>
      <el-dialog
        title="新增数据域"
        :visible.sync="addDataFieldVisible"
        width="60%"
      >
        <el-form :model="addDataFieForm">
          <el-form-item label="活动名称:">
            <el-input
              style="width: 256px;"
              v-model="addDataFieForm.name"
            ></el-input>
          </el-form-item>
          <el-row>
            <!-- <el-col :span="3">
							<span style="color: #5F6297;font-size: 14px; ">添加相关区域：</span>
						</el-col>
						<el-col :span="3">
							<el-select v-model="addDataFieForm.coalValue" placeholder="选择项目">
								<el-option v-for="item in coaloptions" :key="item.value" :label="item.label" :value="item.value">
								</el-option>
							</el-select>
							<el-button class="add" type="primary">+</el-button>
						</el-col>
						<el-col :span="3" style="margin-left: 3px">
							<el-select v-model="addDataFieForm.systemValue" placeholder="选择系统">
								<el-option v-for="item in systemoptions" :key="item.value" :label="item.label" :value="item.value">
								</el-option>
							</el-select>
							<el-button class="add" type="primary">+</el-button>
						</el-col>
						<el-col :span="3" style="margin-left: 3px">
							<el-select v-model="addDataFieForm.subsystemValue" placeholder="选择子系统">
								<el-option v-for="item in subsystemoptions" :key="item.value" :label="item.label" :value="item.value">
								</el-option>
							</el-select>
							<el-button class="add" type="primary">+</el-button>
						</el-col>
						<el-col :span="3" style="margin-left: 3px">
							<el-select v-model="addDataFieForm.deviceValue" placeholder="选择设备">
								<el-option v-for="item in deviceoptions" :key="item.value" :label="item.label" :value="item.value">
								</el-option>
							</el-select>
							<el-button class="add" type="primary">+</el-button>
						</el-col> -->
            <span style="color: #5F6297;font-size: 14px; ">添加相关区域：</span>
            <el-tree
              :data="treedata"
              :props="treeProps"
              show-checkbox
              node-key="id"
              accordion
              ref="treeRef"
            ></el-tree>
          </el-row>
          <el-form-item
            label="数据域简介:"
            prop="datafileDes"
            style="margin-top: 10px"
          >
            <el-input
              style="width: 256px;"
              v-model="addDataFieForm.remark"
              placeholder="请输入简介"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="当前操作员:"
            prop="currOperator"
          >
            <el-input
              style="width: 256px;"
              v-model="addDataFieForm.currOperator"
              placeholder="管理员user"
              disabled
            ></el-input>
          </el-form-item>
        </el-form>
        <div
          slot="footer"
          class="dialog-footer"
        >
          <el-button @click="addDataFieldVisible = false">取 消</el-button>
          <el-button
            type="primary"
            @click="addDataFieldmethod"
          >确 定</el-button>
        </div>
      </el-dialog>
    </div>
    <!-- 修改数据域的对话框 -->
    <div>
      <el-dialog
        title="修改数据域"
        :visible.sync="updateDataFieldVisible"
        width="60%"
        @close="setupdateDataFieDigClosed"
        v-if="updateDataFieldVisible"
      >
        <el-form :model="updateDataFieForm">
          <el-form-item label="活动名称:">
            <el-input
              style="width: 256px;"
              v-model="updateDataFieForm.name"
            ></el-input>
          </el-form-item>
          <span style="color: #5F6297;font-size: 14px; ">修改相关区域：</span>
          <el-tree
            :data="treedata"
            :props="treeProps"
            show-checkbox
            node-key="id"
            accordion
            :default-checked-keys="defKeys"
            :default-expand-all="true"
            ref="updatetree"
          ></el-tree>
          <el-form-item
            label="数据域简介:"
            prop="datafileDes"
            style="margin-top: 10px"
          >
            <el-input
              style="width: 256px;"
              v-model="updateDataFieForm.remark"
              placeholder="请输入简介"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="当前操作员:"
            prop="currOperator"
          >
            <el-input
              style="width: 256px;"
              v-model="updateDataFieForm.currOperator"
              placeholder="管理员user"
              disabled
            ></el-input>
          </el-form-item>
        </el-form>
        <div
          slot="footer"
          class="dialog-footer"
        >
          <el-button @click="updateDataFieldVisible = false">取 消</el-button>
          <el-button
            type="primary"
            @click="updateDataFieldComfirmed"
          >确 定</el-button>
        </div>
      </el-dialog>
    </div>

    <!-- 查看数据域的对话框 -->
    <div>
      <el-dialog
        title="查看数据域"
        :visible.sync="watchDataFieldVisible"
        width="60%"
        v-if="watchDataFieldVisible"
      >
        <!-- <el-tree :data="treedata" :props="treeProps" show-checkbox node-key="id" accordion :default-checked-keys="watchdefKeys" :default-expand-all="true"></el-tree> -->
        <el-input
          placeholder="输入关键字进行过滤"
          v-model="filterText"
        >
        </el-input>

        <el-tree
          show-checkbox
          class="filter-tree"
          :data="treedata"
          :props="treeProps"
          default-expand-all
          node-key="id"
          :default-checked-keys="watchdefKeys"
          :filter-node-method="filterNode"
          ref="watchtree"
        >
        </el-tree>
        <div
          slot="footer"
          class="dialog-footer"
        >
          <el-button @click="watchDataFieldVisible = false">取 消</el-button>
          <el-button
            type="primary"
            @click="watchDataFieldVisible = false"
          >确 定</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
export default {
  watch: {
    filterText(val) {
      this.$refs.watchtree.filter(val);
    },
  },

  mounted() {
    // this.initData();

    this.getRequest("/dataManager/getTreeData/").then((resp) => {
      if (resp.data) {
        this.data = resp.data;
        console.log(resp.data);
        this.treedata = this.treeDataTranslate(this.data);
        console.log(this.treedata);
      }
    });

    //根据名字查询系统设置中的结果
    this.getRequest(
      "/systemSetting/getSettingInfoByName/?name=" + this.name
    ).then((resp) => {
      if (resp.data) {
        console.log(this.name);
        console.log(resp.data);
        this.setting.batchRemove = resp.data.batchremove;
        this.setting.advancesearch = resp.data.advancesearch;
        this.size = resp.data.numberperpage;
      }
    });
  },
  name: "dataFieldManage",
  data() {
    return {
      name: "数据域管理",
      // 点击确认修改后的id
      updateids: "",
      watchDataFieldVisible: false,
      idStr: "",
      multipleSelection: [],
      coaloptions: [
        {
          value: "选项1",
          label: "王家岭项目",
        },
        {
          value: "选项2",
          label: "张家岭项目",
        },
        {
          value: "选项3",
          label: "巴拉素项目",
        },
      ],
      systemoptions: [
        {
          value: "选项1",
          label: "系统1",
        },
        {
          value: "选项2",
          label: "系统2",
        },
        {
          value: "选项3",
          label: "系统3",
        },
      ],
      subsystemoptions: [
        {
          value: "选项1",
          label: "子系统1",
        },
        {
          value: "选项2",
          label: "子系统2",
        },
        {
          value: "选项3",
          label: "子系统3",
        },
      ],
      deviceoptions: [
        {
          value: "选项1",
          label: "设备1",
        },
        {
          value: "选项2",
          label: "设备2",
        },
        {
          value: "选项3",
          label: "设备3",
        },
      ],
      addDataFieForm: {
        id: "",
        currOperator: "",
        remark: "",
        coalValue: "",
        systemValue: "",
        subsystemValue: "",
        deviceValue: "",
        name: "",
        region: "",
        date1: "",
        date2: "",
        delivery: false,
        type: [],
        resource: "",
        desc: "",
        ids: "",
      },

      updateDataFieForm: {
        id: "",
        name: "",
        remark: "",
        currOperator: "",
        systemValue: "",
        ids: "",
      },
      formLabelWidth: "120px",
      addDataFieldVisible: false,
      updateDataFieldVisible: false,
      dataFieldName: "",
      page: 1, //当前第几页
      size: 10, //当前每页显示数量
      total: 100, //全部数据量
      options: [
        {
          title: "数据域个数",
          num: "14个",
        },
        {
          title: "涉及矿区数量",
          num: "3个",
        },
        {
          title: "涉及系统个数",
          num: "6个",
        },
      ],
      // coaloptions: [
      //   {
      //     value: "选项1",
      //     label: "王家岭项目",
      //   },
      //   {
      //     value: "选项2",
      //     label: "小保当项目",
      //   },
      //   {
      //     value: "选项3",
      //     label: "大海则项目",
      //   },
      // ],
      // systemoptions: [
      //   {
      //     value: "选项1",
      //     label: "主运输系统",
      //   },
      //   {
      //     value: "选项2",
      //     label: "通风系统",
      //   },
      //   {
      //     value: "选项3",
      //     label: "主排水系统",
      //   },
      // ],
      setting: {
        batchRemove: true,
        advancesearch: true,
      },
      coalValue: "",
      systemValue: "",
      tableData: [
        {
          id: 1,
          dataField: "数据域1",
          coal: "矿区1",
          system: "系统1",
          createDate: "2019",
          remark: "一个怎样的数据域",
        },
        {
          id: 1,
          dataField: "数据域2",
          coal: "矿区2",
          system: "系统1",
          createDate: "2019",
          remark: "一个怎样的数据域",
        },
        {
          id: 1,
          dataField: "数据域3",
          coal: "矿区3",
          system: "系统3",
          createDate: "2019",
          remark: "一个怎样的数据域",
        },
        {
          id: 1,
          dataField: "数据域4",
          coal: "矿区4",
          system: "系统4",
          createDate: "2019",
          remark: "一个怎样的数据域",
        },
        {
          id: 1,
          dataField: "数据域5",
          coal: "矿区5",
          system: "系统5",
          createDate: "2019",
          remark: "一个怎样的数据域",
        },
      ],
      // 修改数据域的默认数组
      defKeys: [],
      // 查看数据域的默认数组
      watchdefKeys: [],
      treedata: [],
      // data: [{ parentId: 0, id: 1, value: '1' },
      //  { parentId: 3, id: 2, value: '2' },
      //  { parentId: 0, id: 3, value: '3' },
      //  { parentId: 1, id: 4, value: '4' },
      //   { parentId: 1, id: 5, value: '5' }],
      data: [],
      treeProps: {
        children: "children",
        label: "value",
      },
      filterText: "",
      // 当前即将修改的数据域所对应的id值
      datafiId: "",
      datafileInfo: {},
    };
  },

  methods: {
    filterNode(value, treedata) {
      if (!value) return true;
      return treedata.value.indexOf(value) !== -1;
    },

    //   确认修改数据域
    updateDataFieldComfirmed() {
      const updatekeys = [
        ...this.$refs.updatetree.getCheckedKeys(),
        // ...this.$refs.treeRef.getHalfCheckedKeys()
      ];
      this.updateDataFieForm.ids = updatekeys.join(",");
      this.updateDataFieForm.id = this.datafiId;
      console.log(this.updateDataFieForm);
      //  this.putRequest('dataManager/updateDataFieldIds/?id=' + this.datafiId + '&ids='+this.addDataFieForm.ids).then(resp=>{
      this.putRequest(
        "dataManager/updateDataFieldInfo",
        this.updateDataFieForm
      ).then((resp) => {
        if (resp.data) {
          this.initData();
        }
      });
      // this.addDataFieForm.ids='';
      this.updateDataFieldVisible = false;
    },
    // 修改数据域对话框的关闭事件
    setupdateDataFieDigClosed() {
      console.log("点击关闭按钮清空后的id值");
      this.defKeys = [];
      console.log(this.defKeys);
    },
    // setupdateDataFieDigCanceled(){
    // 	this.defKeys=[];
    // 	this.updateDataFieldVisible=false;

    // },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    deleteMany() {
      this.$confirm(
        "此操作将永久删除【" +
          this.multipleSelection.length +
          "】条记录, 是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          let ids = "?";
          this.multipleSelection.forEach((item) => {
            ids += "ids=" + item.id + "&";
          });
          this.deleteRequest("/dataManager/deleteDataField/" + ids).then(
            (resp) => {
              if (resp.data) {
                this.initData();
              }
            }
          );
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    //查看数据域
    watchdatafile(row) {
      // console.log("点击修改后从数据库读取到本行的id值"+row)
      this.watchdefKeys.length = 0;
      this.watchdefKeys = row.ids.split(",");
      this.watchDataFieldVisible = true;
	  this.addForm.currOperator = JSON.parse(
      window.sessionStorage.getItem("user")
    ).userId;
      console.log(this.watchdefKeys);
    },
    //修改数据域
    updatedatafile(row) {
      console.log(row);
      this.updateDataFieForm.name = row.dataField;
      this.updateDataFieForm.remark = row.remark;
      this.datafiId = row.id;
      this.defKeys.length = 0;
      this.defKeys = row.ids.split(",");
      console.log(this.defKeys);
      // console.log(this.defKeys);
      this.updateDataFieldVisible = true;
    },
    deleteDataField(id) {
      this.$confirm("此操作将永久删除该项目, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let ids = "?ids=" + id;
          this.deleteRequest("/dataManager/deleteDataField/" + ids).then(
            (resp) => {
              if (resp.data) {
                this.initData();
              }
            }
          );
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    queryBydataFieldName() {
      this.coalValue = "";
      this.systemValue = "";
      this.getRequest(
        "/dataManager/getDataFieldInfo/?dataFieldName=" +
          this.dataFieldName +
          "&page=" +
          this.page +
          "&size=" +
          this.size
      ).then((resp) => {
        if (resp.data) {
          this.tableData = resp.data;
          this.total = resp.data.size;
        }
      });
    },
    queryByCondition() {
      this.getRequest(
        "/dataManager/getDataFieldInfo/?coal=" +
          this.coalValue +
          "&system=" +
          this.systemValue +
          "&page=" +
          this.page +
          "&size=" +
          this.size
      ).then((resp) => {
        if (resp.data) {
          this.tableData = resp.data;
          this.total = resp.data.size;
        }
      });
    },
    initData() {
      this.getRequest(
        "/dataManager/getDataFieldInfo/?page=" +
          this.page +
          "&size=" +
          this.size
      ).then((resp) => {
        if (resp.data) {
          this.tableData = resp.data;
          this.total = resp.data.size;
        }
      });
      /*this.getRequest('/coalManage/getCoalOverview').then(resp.data =>{
					if (resp.data){
						this.options[0].num = resp.data.coalCount + '个';
						this.options[1].num = resp.data.systemCount + '个';
						this.options[2].num = resp.data.subSystemCount + '个';
					}
				})*/
    },
    // returnTreeData(){
    // 	treeDataTranslate(res.data,'id','pid')
    // },
    currentChange(currentPage) {
      this.page = currentPage;
      this.initData();
    },
    sizeChange(currentSize) {
      this.size = currentSize;
      this.initData();
    },

    addDatafile() {
      this.addDataFieldVisible = true;
    },
    // 点击新增数据域
    addDataFieldmethod() {
      this.addDataFieldVisible = false;
      const keys = [
        ...this.$refs.treeRef.getCheckedKeys(),
        // ...this.$refs.treeRef.getHalfCheckedKeys()
      ];
      this.addDataFieForm.ids = keys.join(",");
      // console.log(this.addDataFieForm.ids);
      this.postRequest(
        "/dataManager/addDataFieldInfo",
        this.addDataFieForm
      ).then((resp) => {
        if (resp.data) {
          this.initData();
        }
      });
      this.addDataFieForm.ids = "";
    },
  },
};
</script>

<style scoped>
.el-form {
  margin-top: 12px;
}
.add {
  width: 80px;
  margin-top: 10px;
}

.datafileblock {
  width: 115px;
  height: 77px;
  border: solid 1px #dcdfe6;
  padding-top: 20px;
  margin-left: 25px;
  text-align: center;
  display: inline-block;
}
</style>
